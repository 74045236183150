import React from "react";
import {Helmet} from "react-helmet";
//import { Step } from "../../util/type";


const Register = ({step} : any) => {
    
    return (
        <>
            <Helmet>
                <title>Afrisends | Connexion à Afrisends</title>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="/old-assets/css/vendor.min.css" />
                <link rel="stylesheet" href="/old-assets/css/theme.min.css?v=1.0" />
                <link rel="stylesheet" href="/old-assets/css/login.css" />
            </Helmet>
            <main id="content" role="main">
            <div className="d-lg-flex half">
            <div className="bg order-1 order-md-2"
                 style={{ background: "linear-gradient(#ff64001c 25%, #ff6400b5 90%), " +
                         "linear-gradient(to right, #ff64001c 0%, #ef88453d 90%), " +
                         "url(/old-assets/img/others/delivery-concept.jpg) no-repeat", backgroundSize:'cover' }}
            />
            <div className="contents order-2 order-md-1 justify-content-center">
                <div className="container">

                    <div className="row  justify-content-center">
                        <div className="col-md-8 login-content">
                            <h2>Working in progress</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </main>
        </>

    );
}
export default Register;
